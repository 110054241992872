import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { Card } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalPosition } from '~/@types/position'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useIsHover } from '@unlikelystudio/react-hooks'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image from '~/components/UI/Image'
import Link from '~/components/UI/Link'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 24 / 24 }]

type NamePreset =
  | GlobalTextPreset.Title12_14HafferSemiBold
  | GlobalTextPreset.Title10_14HafferSemiBold
  | GlobalTextPreset.Title14HafferSemiBold
  | GlobalTextPreset.Title18_24HafferSemiBold

type SuptitlePreset = GlobalTextPreset.Label10_12HafferSemiBold

type LabelPreset = GlobalTextPreset.Text12_14Haffer

type TextAlign = Omit<GlobalPosition, 'TOP' | 'BOTTOM'>

export interface ArticleCardProps extends Card {
  className?: string
  suptitle?: RichTextBlocks
  suptitlePreset?: SuptitlePreset
  namePreset?: NamePreset
  labelPreset?: LabelPreset
  textAlign?: TextAlign
}

function ArticleCard({
  className,
  link,
  image,
  imageHover,
  sizesFromBreakpoints,
  ratio,
  suptitle,
  name,
  label,
  suptitlePreset,
  namePreset,
  labelPreset,
  textAlign,
  pin,
  priority,
}: ArticleCardProps) {
  const [isHover, callbacks] = useIsHover()

  const suptitleStyle = useStyle({
    textPreset: suptitlePreset,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const nameStyle = useStyle({
    textPreset: namePreset,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const labelStyle = useStyle({
    textPreset: labelPreset,
    color: GlobalThemeColors.MineShaft,
  })

  const pinStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const hasContent = isRTFilled(suptitle) || name || isRTFilled(label)

  return (
    <Link className={cx(css.ArticleCard, className)} {...link} {...callbacks}>
      <div className={cx(css.images)}>
        {image && (
          <Image
            draggable="false"
            layout="fill"
            objectFit="cover"
            className={cx(css.image)}
            sizesFromBreakpoints={sizesFromBreakpoints || IMAGE_SIZES}
            ratio={ratio || css.ratio}
            asPlaceholder
            priority={priority}
            {...image}
          />
        )}

        {imageHover && (
          <AnimatePresence>
            {isHover && (
              <m.div
                className={cx(css.imageHover)}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.25 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.25 },
                }}>
                {imageHover && (
                  <Image
                    draggable="false"
                    layout="fill"
                    objectFit="cover"
                    sizesFromBreakpoints={sizesFromBreakpoints || IMAGE_SIZES}
                    ratio={ratio || css.ratio}
                    asPlaceholder
                    {...imageHover}
                  />
                )}
              </m.div>
            )}
          </AnimatePresence>
        )}
      </div>

      {hasContent && (
        <div className={cx(css.texts, css?.[`text-align-${textAlign}`])}>
          <RichText
            className={cx(suptitleStyle, css.suptitle)}
            render={suptitle}
          />
          {name && <h3 className={cx(nameStyle, css.name)}>{name}</h3>}
          <RichText className={cx(labelStyle, css.label)} render={label} />
        </div>
      )}
      <RichText className={cx(pinStyle, css.pin)} render={pin} />
    </Link>
  )
}

ArticleCard.defaultProps = {
  suptitlePreset: GlobalTextPreset.Label10_12Haffer,
  namePreset: GlobalTextPreset.Title18_24HafferSemiBold,
  labelPreset: GlobalTextPreset.Text12_14Haffer,
  textAlign: GlobalPosition.LEFT,
}

export default ArticleCard
